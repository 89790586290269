import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'LIFESTYLE'}
              title={'The Life-Changing Magic of Tidying Up'}
              image={'/books/the_life_changing_magic_of_tidying_up.jpg'}
              alt={''}
            >
              <div className={styles.content}>
              <br></br>
              <h2>ENG</h2>
              <br></br>
              <p className={styles.excerpt}>

              "The Life-Changing Magic of Tidying Up" is a best-selling book by Marie Kondo that presents a revolutionary approach to decluttering and organizing your home. The book is based on Kondo's own experiences as a professional tidying consultant, and it provides readers with a step-by-step guide on how to transform their homes and their lives through the process of tidying up.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kondo's approach to tidying up is based on the principle of "spark joy." She believes that people should only keep items that bring them joy and discard everything else. This means that instead of focusing on what to get rid of, people should focus on what they want to keep. Kondo advises people to gather all of their belongings in one place and go through them one by one, asking themselves if each item sparks joy. If an item does not spark joy, then it should be discarded.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              One of the key takeaways from the book is the importance of tidying by category rather than by location. Kondo advises people to tidy in the following order: clothes, books, papers, komono (miscellaneous items), and sentimental items. This allows people to see everything they own in a particular category and make decisions about what to keep and what to discard.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Another important principle in the book is the importance of properly folding and storing clothes. Kondo teaches readers how to fold clothes in a way that not only saves space but also allows them to see everything they own at a glance. She recommends storing clothes vertically rather than horizontally, which not only saves space but also makes it easier to find what you're looking for.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kondo also emphasizes the importance of expressing gratitude for the items that you choose to keep. She believes that by thanking each item for its service, people can create a positive relationship with their belongings and make it easier to let go of them when the time comes.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              One of the most powerful aspects of the book is the way in which it connects tidying up with personal transformation. Kondo believes that by tidying up, people can let go of the past, create space for new opportunities, and gain a sense of clarity and purpose in their lives. She encourages readers to ask themselves what kind of life they want to live and to use tidying up as a tool to help them achieve their goals.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Overall, "The Life-Changing Magic of Tidying Up" is a practical and inspiring guide to decluttering and organizing your home. It provides readers with a simple yet powerful framework for tidying up and encourages them to see the process as a way to transform their lives. Whether you're someone who struggles with clutter or just looking for a new approach to organizing your space, this book is a must-read.

              </p>
              </div>

              <div className={styles.content}>
              <br></br>
              <h2>LV</h2>
              <br></br>
              <p className={styles.excerpt}>

              “Sakārtošanas maģija, kas maina dzīvi” ir visvairāk pārdotā Marijas Kondo grāmata, kas piedāvā revolucionāru pieeju jūsu mājas pārkārtošanai un sakārtošanai. Grāmata ir balstīta uz pašas Kondo kā profesionālas uzkopšanas konsultantes pieredzi, un tā sniedz lasītājiem soli pa solim ceļvedi par to, kā sakārtot savas mājas un dzīvi, veicot uzkopšanas procesu.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kondo pieeja sakopšanai ir balstīta uz "prieka dzirksteles" principu. Viņa uzskata, ka cilvēkiem vajadzētu paturēt tikai lietas, kas sagādā prieku, un izmest visu pārējo. Tas nozīmē, ka tā vietā, lai koncentrētos uz to, no kā atbrīvoties, cilvēkiem vajadzētu koncentrēties uz to, ko viņi vēlas paturēt. Kondo iesaka cilvēkiem savākt visas savas mantas vienuviet un iziet tām pa vienai, jautājot sev, vai katra lieta rada prieku. Ja priekšmets nerada prieku, tad tas ir jāizmet.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Viena no galvenajām grāmatas iezīmēm ir sakārtotība pēc kategorijas, nevis pēc atrašanās vietas. Kondo iesaka cilvēkiem kārtot šādā secībā: drēbes, grāmatas, papīri, komono (dažādi priekšmeti) un sentimentālas lietas. Tas ļauj cilvēkiem redzēt visu, kas viņiem pieder noteiktā kategorijā, un pieņemt lēmumus par to, ko paturēt un ko izmest.
                  
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Vēl viens svarīgs grāmatas princips ir apģērba pareizas locīšanas un uzglabāšanas nozīme. Kondo māca lasītājiem, kā salocīt drēbes tā, lai ne tikai ietaupītu vietu, bet arī ļautu īsumā redzēt visu, kas viņiem pieder. Viņa iesaka glabāt drēbes vertikāli, nevis horizontāli, kas ne tikai ietaupa vietu, bet arī atvieglo meklējamā atrašanu.
              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kondo arī uzsver, cik svarīgi ir izteikt pateicību par priekšmetiem, kurus izvēlaties paturēt. Viņa uzskata, ka, pateicoties katrai lietai par servisu, cilvēki var izveidot pozitīvas attiecības ar savām mantām un atvieglot to atlaišanu, kad pienāks laiks.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Viens no visspēcīgākajiem grāmatas aspektiem ir veids, kādā tā sakārto sakārtošanu ar personīgo transformāciju. Kondo uzskata, ka, sakopjoties, cilvēki var atbrīvoties no pagātnes, radīt vietu jaunām iespējām un iegūt skaidrības un mērķtiecības sajūtu savā dzīvē. Viņa mudina lasītājus uzdot sev jautājumu, kādu dzīvi viņi vēlas dzīvot, un izmantot uzkopšanu kā līdzekli, kas palīdz sasniegt savus mērķus.

              </p>
              <br></br>
              <p className={styles.excerpt}>

              Kopumā "Sakārtošanas burvība, kas maina dzīvi" ir praktisks un iedvesmojošs ceļvedis jūsu mājas pārkārtošanai un sakārtošanai. Tas sniedz lasītājiem vienkāršu, bet jaudīgu sakārtošanas sistēmu un mudina viņus uztvert šo procesu kā veidu, kā pārveidot savu dzīvi. Neatkarīgi no tā, vai jūs cīnās ar jucekli vai vienkārši meklējat jaunu pieeju savas telpas organizēšanai, šī grāmata ir noteikti jāizlasa.

              </p>
              </div>
            </Blog>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
